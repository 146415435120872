<template>
  <VTable
    :head-row="PERSONAL_AREA_CHANGELOG_TH"
    :is-checkbox="false"
    url-set-rows="/registers/change-history"
    :url-action-query="`search[User]=${getId}`"
    :transform-object="transformChangelogUser"
  />
</template>

<script setup>
import VTable from '@/components/ui/table/VTable'

import { computed, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { PERSONAL_AREA_CHANGELOG_TH } from '@/utils/consts/tableHead'
import { transformChangelogUser } from '@/utils/model/Changelog/transform'

const route = useRoute()
const id = ref(0)

onBeforeMount(() => {
  id.value = route.params.id
})

const getId = computed(() => id.value)
</script>
